export const Tip = {
  async mounted() {
    const { default: tippy } = await import("tippy.js");
    if (!this.el.dataset.tip) {
      console.warn("No tip provided for element :", this.el);
      return;
    }
    tippy(this.el, {
      content: this.el.dataset.tip,
      placement: this.el.dataset.placement || "top",
      allowHTML: true,
    });
  },
};
